import React from "react";

interface IResourceDownloadEmailContext {
  hasError: boolean;
  setHasError: (value: boolean) => void;
  email: string;
  setEmail: (value: string) => void;
  typeSelected?: string;
  setTypeSelected?: (value: string) => void;
  typeError?: boolean;
  setTypeError?: (value: boolean) => void;
}

export const formInitialCtx: IResourceDownloadEmailContext = {
  hasError: false,
  setHasError: () => {},
  email: "",
  setEmail: () => {},
  typeSelected: "",
  setTypeSelected: () => {},
  typeError: false,
  setTypeError: () => {},
};

export const ResourceDownloadEmailContext = React.createContext(formInitialCtx);
