import React, { useState } from "react";
import styles from "../../Modals/Modal/Modal.module.scss";
import stylesCapture from "../../Modals/EmailCaptureModal/EmailCaptureModal.module.scss";
import {
  useModalResourceBuilder,
  useModalResourceDownloadBuilder,
} from "@sp-state/modals/useModalResourceBuilder";
import { Cookie } from "@utils/Cookie";
import { mxpResourceDownload } from "@utils/mixpanelEvents/mixpanelEvents";
import { ModalResourceType } from "src/assets/Types/ModalResource.type";
import EmailCaptureForm from "../EmailCaptureForm";
import { ResourceDownloadEmailContext } from "@contexts/ResourceDownloadEmailContext";
import { resourceDownload } from "@utils/segment/resourceDownload";

const ModalResourceBuilder: React.FC<ModalResourceType> = ({
  header,
  subheaderCopy,
  subcopy,
  ctaText,
  ctaLink,
  handleDownload,
  fileName,
}) => {
  const { modalOpen, setModalResourceBuilder } = useModalResourceBuilder(
    state => state
  );

  const setModalResourceDownloadBuilder = useModalResourceDownloadBuilder(
    state => state.setModalResourceDownloadBuilder
  );

  const [hasError, setHasError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [typeSelected, setTypeSelected] = useState<string>("");
  const [typeError, setTypeError] = useState<boolean>(false);
  const handleClose = () => {
    setHasError(false);
    setTypeError(false);
    setModalResourceBuilder(false);
    setEmail("");
    setTypeSelected("");
  };

  const handleCustomFinish = () => {
    const spResource = Cookie.getCookie("sp_resource");
    const spLastAccess = Cookie.getCookie("sp_last_access");

    mxpResourceDownload(spResource, spLastAccess, ctaText);
    resourceDownload(ctaText, fileName);
    Cookie.setCookie("sp_resource", "true", 365);
    handleClose();

    if (ctaLink) {
      setModalResourceDownloadBuilder(true);
      handleDownload();
    }
  };

  return (
    <div
      data-testid="modal"
      className={`${styles.modalContainer} ${
        modalOpen && styles.modalContainerActive
      }`}
    >
      <div
        className={`${styles.modalContent}  ${
          modalOpen && styles.modalContentActive
        }`}
      >
        <div className={styles.modalBody}>
          <h5
            data-testid="modal-header"
            className={stylesCapture.modalBodyTitle}
          >
            {header}
          </h5>
          <p
            data-testid="modal-subheader"
            className={stylesCapture.modalBodySubtitle}
          >
            {subheaderCopy}
          </p>
          <ResourceDownloadEmailContext.Provider
            value={{
              hasError,
              setHasError,
              email,
              setEmail,
              typeSelected,
              setTypeSelected,
              typeError,
              setTypeError,
            }}
          >
            <EmailCaptureForm
              ctaText={ctaText}
              handleCustomFinish={handleCustomFinish}
              fileName={fileName}
              withSelect
            />
          </ResourceDownloadEmailContext.Provider>
          <p data-testid="modal-subcopy" className={stylesCapture.emailSubcopy}>
            {subcopy}
          </p>
          <button
            type="button"
            onClick={handleClose}
            className={`${styles.closeBtn} ${styles.closeBtnAlt}`}
          >
            <img src="/icons/close.svg" alt="Close Icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalResourceBuilder;
